/**
 * グローバルに必要な定数をまとめる
 */

export const LOGIN_UUID_SESSION_STORAGE_KEYNAME = "uuid";
// localStorageは改ざんの可能性がある為、URL直ではなくリダイレクト先パターンの形で保持する
export const LOGIN_REDIRECT_SESSION_STORAGE_KEYNAME = "redirect";
export const LOGIN_REDIRECT_SESSION_STORAGE_VALUE_OTHER_MYPAGE = "mypage";

export const FOOTER_HEIGHT_PX = 56;
export const VIEWPORT_WIDTH_PX = 375;

export default null;